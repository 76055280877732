import {
  PayloadAction,
  bindActionCreators,
  createSlice,
} from '@reduxjs/toolkit'
import { useMemo } from 'react'
import { useDispatch } from 'react-redux'

import MODAL_NAME from '@constants/modal-name'

type StateProps = {
  isLoading: boolean
  isModalOpen: boolean
  modalName: keyof typeof MODAL_NAME | null
  isMobile: boolean
  isPlayerOpen: boolean
  isImageHovering: boolean
  isFreeCoinsBannerOpen: boolean
  isFreeCoinsModalOpen: boolean
}

const initialState: StateProps = {
  isLoading: false,
  isModalOpen: false,
  modalName: null,
  isMobile: false,
  isPlayerOpen: false,
  isImageHovering: false,
  isFreeCoinsBannerOpen: false,
  isFreeCoinsModalOpen: false,
}

const appSlice = createSlice({
  name: 'app',
  initialState,
  reducers: {
    setIsLoading: (state, action: PayloadAction<boolean>) => {
      state.isLoading = action.payload
    },
    setIsModalOpen: (
      state,
      action: PayloadAction<StateProps['isModalOpen']>
    ) => {
      state.isModalOpen = action.payload
    },
    setModalName: (state, action: PayloadAction<StateProps['modalName']>) => {
      state.modalName = action.payload
    },
    removeModal: (state) => {
      state.modalName = null
    },
    setIsMobile: (state, action: PayloadAction<StateProps['isMobile']>) => {
      state.isMobile = action.payload
    },
    setIsPlayerOpen: (
      state,
      action: PayloadAction<StateProps['isPlayerOpen']>
    ) => {
      state.isPlayerOpen = action.payload
    },
    setIsImageHovering: (
      state,
      action: PayloadAction<StateProps['isImageHovering']>
    ) => {
      state.isImageHovering = action.payload
    },
    setIsFreeCoinsBannerOpen: (
      state,
      action: PayloadAction<StateProps['isFreeCoinsBannerOpen']>
    ) => {
      state.isFreeCoinsBannerOpen = action.payload
    },
    setIsFreeCoinsModalOpen: (
      state,
      action: PayloadAction<StateProps['isFreeCoinsModalOpen']>
    ) => {
      state.isFreeCoinsModalOpen = action.payload
    },
  },
})

export const appActions = appSlice.actions

export const useAppActions = () => {
  const dispatch = useDispatch()
  return useMemo(
    () => bindActionCreators(appActions, dispatch),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [dispatch]
  )
}

export default appSlice.reducer

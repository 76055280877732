import { get } from '@helpers/fetch-wrapper'
import router from 'next/router'
import React, { ReactNode, useEffect, useState } from 'react'

import { GetEmployerProfileAPIResponseData } from '@features/kyc/types'

import { useAppSelector } from '@hooks/redux'

import { ACCESS_CREATED_NEW_ACCOUNT } from '@constants/event-tracking'
import { AUTH_PATH, PUBLIC_PATH } from '@constants/paths'
import { ATMA_EMPLOYER_ACCESS_TOKEN } from '@constants/storage-items'
import { EMPLOYERS_PROFILE_URL } from '@constants/url'

import trackEvents from '@utils/track-events'

type Props = {
  children: ReactNode
}

/**
 * This function has to be awaited to simulate sleep/delay
 * @param ms milliseconds
 * @returns Promise
 */
const sleep = (ms: number) =>
  new Promise((resolve) => {
    setTimeout(resolve, ms)
  })

const AuthGuard: React.FC<Props> = ({ children }: Props) => {
  const [finishUserAuthCheck, setFinishUserAuthCheck] = useState(false)

  const isOps = useAppSelector((state) => state.profileReducer.isOps)

  const userType = localStorage.getItem('userType')
  const selectedPathName = () => {
    if (userType === 'ps') {
      return '/ps-login'
    }
    if (isOps) {
      return '/mac-login'
    }
    return '/login'
  }

  const authCheck = async () => {
    const accessToken = localStorage.getItem(ATMA_EMPLOYER_ACCESS_TOKEN)

    // Path accessed by url/from searchbar or first load page
    const accessedPathIsPublicPath = Object.values(PUBLIC_PATH).includes(
      router.pathname
    )

    if (!accessToken) {
      if (!accessedPathIsPublicPath) {
        await router.replace({
          pathname: selectedPathName(),
          ...(router.query.returnUrl && {
            query: router.query,
          }),
        })
        localStorage.removeItem('userType')
        await sleep(100)
        setFinishUserAuthCheck(true)
        return
      }

      setFinishUserAuthCheck(true)
      return
    }

    const getEmployerProfileResp = await get(EMPLOYERS_PROFILE_URL)

    const employerProfileData: GetEmployerProfileAPIResponseData =
      getEmployerProfileResp.data

    const isUserOnboarded = employerProfileData.isPhantom
      ? true
      : employerProfileData.isNewUser === false &&
        Boolean(employerProfileData.fullName) &&
        Boolean(employerProfileData.company?.id) &&
        Boolean(employerProfileData.company?.employee?.designationId)

    if (
      userType === 'ps' &&
      (router.pathname === '/' || router.pathname === '/jobs')
    ) {
      await router.replace('/premium-service/candidate')
      await sleep(100)
      setFinishUserAuthCheck(true)
      return
    }

    if (employerProfileData.isNewUser !== undefined) {
      if (AUTH_PATH.includes(router.pathname) && isUserOnboarded) {
        await router.replace({
          pathname: userType === 'ps' ? '/premium-service/candidate' : '/jobs',
        })
        await sleep(100)
        setFinishUserAuthCheck(true)
        return
      }

      if (
        !isUserOnboarded &&
        router.pathname !== '/onboarding/account-info' &&
        router.pathname !== '/onboarding/company-info' &&
        router.pathname !== '/onboarding/review-account'
      ) {
        if (!employerProfileData.fullName) {
          trackEvents(ACCESS_CREATED_NEW_ACCOUNT)
          await router.replace({
            pathname: '/onboarding/account-info',
          })
          await sleep(100)
          setFinishUserAuthCheck(true)
          return
        }

        if (
          !employerProfileData.company?.id ||
          !employerProfileData.company?.employee?.designationId
        ) {
          await router.replace({
            pathname: '/onboarding/company-info',
          })
          await sleep(100)
          setFinishUserAuthCheck(true)
        }
      }
    }

    await sleep(100)
    setFinishUserAuthCheck(true)
  }

  useEffect(() => {
    // on initial load - run auth check
    authCheck()

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  if (!finishUserAuthCheck) return null

  return children
}

export default AuthGuard

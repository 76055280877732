import useSWRImmutable from 'swr/immutable'

import { GetEmployerProfileAPIResponseData } from '@features/kyc/types'

import { ATMA_EMPLOYER_ACCESS_TOKEN } from '@constants/storage-items'
import { EMPLOYERS_PROFILE_URL } from '@constants/url'

import customFetcher from '@utils/custom-fetcher'

const employerProfileFetcher = async (url: string) => {
  try {
    const accessToken = localStorage.getItem(ATMA_EMPLOYER_ACCESS_TOKEN)
    if (!accessToken) {
      return null
    }
    return customFetcher(url)
  } catch (error) {
    return null
  }
}

export function useEmployerProfileData() {
  const employerProfileDataQueryResult =
    useSWRImmutable<GetEmployerProfileAPIResponseData>(
      EMPLOYERS_PROFILE_URL,
      employerProfileFetcher,
      {
        shouldRetryOnError: false,
      }
    )

  return employerProfileDataQueryResult
}

export default useEmployerProfileData

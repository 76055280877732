export const PUBLIC_PATH = {
  LOGIN: '/login',
  OPS_LOGIN: '/mac-login',
  PREMIUM_SERVICE_LOGIN: '/ps-login',
  VERIFICATION: '/verification',
  NOT_FOUND: '/404',
}

export const AUTH_PATH = [
  PUBLIC_PATH.LOGIN,
  PUBLIC_PATH.VERIFICATION,
  PUBLIC_PATH.OPS_LOGIN,
  PUBLIC_PATH.PREMIUM_SERVICE_LOGIN,
]

export const PATHS = {
  APPLICATIONS: '/applications',
  JOBS: '/jobs',
  CREATE_JOB: '/jobs/create',
  EDIT_JOB: '/jobs/edit',
  REPOST_JOB: '/jobs/repost',
  UPGRADE_JOB: '/jobs/upgrade',
  SEARCH_CANDIDATE: '/candidates/search',
  CHAT: '/chats',
  INTERVIEWS: '/interviews',
  PROFILE: '/profile',
  EDIT_PROFILE: '/profile/edit',
  PS_CANDIDATE: '/premium-service/candidate',
} as const
